
  import { defineComponent, onMounted, ref, computed } from "vue";
  import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
  import { getIllustrationsPath } from "@/core/helpers/assets";
  import { Field, ErrorMessage, useForm } from "vee-validate";
  import { hideModal, showModal } from "@/core/helpers/dom";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import ApiService from "@/core/services/ApiService";
  import { useRoute, useRouter } from "vue-router";
  import moment from "moment";
  import * as Yup from "yup";

  import loaderClientVue from "@/views/client/components/loader-client.vue";
  
  interface Step1 {
    type_appraisal: string;
    firtsname: string;
    lastname: string;
    email: string;
    phone: string;
  };

  interface Step2 {
    brand: { id: string; name: string };
    model: { id: string; name: string };
    year: { id: string; name: string };
    version: { id: string; name: string };
    owners: number;
    mileage: string;
  };

  interface Step3 {
    diagnostic_file: any;
  };

  interface brand {
    id: string;
    name: string;
  };
  
  interface model {
    id: string;
    name: string;
  };
  
  interface year {
    id: string;
    name: string;
  };
  
  interface version {
    id: string;
    name: string;
  };

  interface KTCreateApp extends Step1, Step2, Step3 {};

  export default defineComponent({
    name: "ModalCreateAppraisal",
    components: {
      Field,
      ErrorMessage,
      loaderClientVue,
    },

    emits: ["updateList"],

    setup(props, { emit }){
      const router = useRouter();
    
      const loading = ref(false);
      const listBrands = ref<brand[]>([]);
      const listModels = ref<model[]>([]);
      const listYears = ref<year[]>([]);
      const listVersions = ref<version[]>([]);
      const brandTemp = ref<any>("");
      const modelTemp = ref<any>("");
      const yearTemp = ref<any>("");
      const versionTemp = ref<any>("");
      const refModalCreateAppraisal = ref<HTMLElement | null>(null);
      
      const types_appraisal = [
        { id:"Compra directa", label: "Compra directa", active: false },
        { id:"A cuenta", label: "A cuenta", active: true },
      ];
      
      const testNumber = null;
      const error_file = ref<boolean>(false);

      const currentStepIndex = ref(0);
      const _stepperObj = ref<StepperComponent | null>(null);
      const createAppRef = ref<HTMLElement | null>(null);
      const formData = ref<KTCreateApp>({
        type_appraisal: "Compra directa",
        firtsname: "",
        lastname: "",
        email: "",
        phone: "",  
        brand: { id: "", name: "Selecciona una marca" },
        model: { id: "", name: "Selecciona un modelo" },
        version: { id: "", name: "Selecciona una versión" },
        year: { id: "", name: "Selecciona una año" },
        owners: 1,
        mileage: "",
        diagnostic_file: { name:"", file:{} },
      });

      const files = ref<any>([]);
      const filediagnostic = ref();
      const inputFile = ref();
      const fileload = ref(false);
      const isEdit = ref<boolean>(false);
  
      const dropEvent = (evt) => {
        let errorFiles = false;
        const file = evt.dataTransfer.files[0];
        const typesFiles = ["image/jpeg","image/png","application/pdf"];
        
        if(typesFiles.includes(file.type)){
          if((file.size / 1024 / 1024) > 10){
            inputFile.value = "";
            filediagnostic.value = "";
            
            Swal.fire({
              icon: "error",
              position: "center",
              showConfirmButton: true,
              title: "Solo se pueden subir archivos inferiores a 10 MB",
            });

          } else {
            fileload.value = true;
            error_file.value = false;
            formData.value.diagnostic_file = file;
          }
        } else {
          inputFile.value = "";
          filediagnostic.value = "";

          Swal.fire({
            timer: 3000,
            icon: "error",
            position: "center",
            showConfirmButton: false,
            title: "Algunos archivos no fueron cargados ya que no tienen el formato esperado",
          });
        }
      };

      const getFilesInput = () => {
        const file = inputFile.value.files[0];
        let errorFiles = false;
        const typesFiles = ["image/jpeg", "image/png", "application/pdf"];

        if(typesFiles.includes(file.type)){
          if((file.size / 1024 / 1024) > 10){
            inputFile.value = "";
            filediagnostic.value = "";
            fileload.value = false;

            Swal.fire({
              icon: "error",
              position: "center",
              showConfirmButton: true,
              title: "Solo se pueden subir archivos inferiores a 10 MB",
            });

          } else {
            fileload.value = true;
            error_file.value = false;
            formData.value.diagnostic_file = file;
          }
        } else {
          inputFile.value = "";
          filediagnostic.value = "";
          fileload.value = false;

          Swal.fire({
            timer: 3000,
            icon: "error",
            position: "center",
            showConfirmButton: false,
            title: "Algunos archivos no fueron cargados ya que no tienen el formato esperado",
          });
        }
      };

      const checkData = (evt) => {
        evt.preventDefault();
      };

      const addImage = () => {
        inputFile.value.click();
      };

      const deleteImage = async (index) => {
        inputFile.value = "";
        fileload.value = false;
        error_file.value = false;
        formData.value.diagnostic_file = {};
      };

    
      const formatNumber = (value) => {
        let val = (parseInt(value) / 1).toFixed(0);
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };

      const addKm = () => {
        let mileage = formData.value.mileage;
        mileage = formData.value.mileage.replace(" ", "");
        mileage = formData.value.mileage.replace("K", "");
        mileage = formData.value.mileage.replace("M", "");
        mileage = mileage.replace(/,/g, ""),
        mileage = formatNumber(mileage);
        mileage = mileage + " KM";
        
        formData.value.mileage = mileage;
      };

      let regexPhone = /^[1-9]\d{2}-\d{3}-\d{4}?$/;

      const createAppSchema = [
        Yup.object({
          type_appraisal: Yup.string()
            .required("Debes de seleccionar un tipo de avalúo")
            .label("Tipo de avalúo"),
          firtsname: Yup.string()
            .required("El campo nombre es obligatorio")
            .label("nombres"),
          lastname: Yup.string()
            .required("El campo apellidos es obligatorio")
            .label("apellidos"),
          email: Yup.string()
            .required("El campo email es obligatorio")
            .email("Ingresa un email valido")
            .label("email"),
          phone: Yup.string()
            .matches(regexPhone, "El teléfono es incorrecto")
            .required("El campo teléfono es obligatorio")
            .label("teléfono"),
        }),

        Yup.object({
          brand: Yup.string()
            .typeError("Debes de seleccionar una marca")
            .required("Debes de seleccionar una marca")
            .notOneOf(["Selecciona una marca"], "Debes seleccionar una marca")
            .label("Marca"),
          model: Yup.string()
            .typeError("Debes de seleccionar un modelo")
            .required("Debes de seleccionar un modelo")
            .notOneOf(["Selecciona un modelo"], "Debes seleccionar un modelo")
            .label("Modelo"),
          year: Yup.string()
            .typeError("Debes de seleccionar un año")
            .required("Debes de seleccionar un año")
            .notOneOf(["Selecciona un año"], "Debes seleccionar un año")
            .label("Año"),
          version: Yup.string()
            .typeError("Debes de seleccionar una versión")
            .required("Debes de seleccionar una versión")
            .notOneOf(["Selecciona una versión"], "Debes seleccionar una versión")
            .label("Versión"),
          owners: Yup.number()
            .positive("Debes de agregar la cantidad de dueños")
            .required("Debes de agregar la cantidad de dueños")
            .label("Dueños"),
          mileage: Yup.string()
            .required("Debes de agregar la cantidad de kms")
            .label("KM"),
        }),
        // Yup.object().shape({
        //     file: Yup.object().shape({
        //     name: Yup.string().required()
        //   })
        //   .required("El archivo de diagnóstico es obligatorio")
        //   .label('diagnóstico')
        // }),
        
        ];

      // extracts the individual step schema
      const currentSchema = computed(() => {
        return createAppSchema[currentStepIndex.value];
      });

      const totalSteps = computed(() => {
        if(!_stepperObj.value){
          return;
        }

        return _stepperObj.value.totatStepsNumber;
      });

      const { resetForm, handleSubmit, setTouched } = useForm < Step1 | Step2 | Step3 > ({
        validationSchema: currentSchema,
      });

      const previousStep = () => {
        if (currentStepIndex.value == 2 ){
          setBrand(true);
          setModel(true);
          setYear(true);
          setVersion(true);
        }
        
        if (currentStepIndex.value == 1 ){
          if ( typeof formData.value.brand.id !== "object"){
            formData.value.brand.id != '' ? brandTemp.value = formData.value.brand.id :  brandTemp.value = '';
          }

          if ( typeof formData.value.model.id !== "object"){
            formData.value.model.id != '' ? modelTemp.value = formData.value.model.id : modelTemp.value = '' ;
          }
          
          if ( typeof formData.value.year.id !== "object"){
            formData.value.year.id != '' ?  yearTemp.value = formData.value.year.id : yearTemp.value = '';
          }
          
          if ( typeof formData.value.version.id !== "object"){
            formData.value.version.id != '' ? versionTemp.value = formData.value.version.id : versionTemp.value = '';
          }  
        }
        
        if(!_stepperObj.value){
          return;
        }

        currentStepIndex.value--;
        (currentStepIndex.value < 0)? currentStepIndex.value = 0 : false ;
        _stepperObj.value.goPrev();
      };

      const confirmTosave = () => {
        handleStep();
      };

      resetForm({
        values: {
          ...formData.value,
        },
      });
      
      const handleStep = handleSubmit(async (values) => {
        resetForm({
          values: {
            ...formData.value,
          },
        });

        setTouched({});

        if(!_stepperObj.value){
          return;
        }

        if(currentStepIndex.value < _stepperObj.value.totatStepsNumber){
          currentStepIndex.value++;
        }

        if(currentStepIndex.value === _stepperObj.value.totatStepsNumber){
          if(!formData.value.diagnostic_file.name){
            currentStepIndex.value--;
            error_file.value = true;
            return;
          } else {
            await formSubmit()
          }

          ;
        } else {
          if (currentStepIndex.value == 1 ){
            brandTemp.value != ""  ? setBrand(true) : "";
            modelTemp.value != "" ? setModel(true) : "";
            yearTemp.value != "" ? setYear(true) : "";
            versionTemp.value != "" ? setVersion(true) : "";
          }
          _stepperObj.value.goNext();
        }
      });

      const formSubmit = async () => {
        loading.value = true;
        const data: any = new FormData();
        const json_data = JSON.stringify(formData.value);
        const new_data = JSON.parse(json_data);

        let mileage = formData.value.mileage;
        mileage = formData.value.mileage.replace(" KM", "");
        mileage = mileage.replace(/,/g, "");

        data.append("tipo", formData.value.type_appraisal);
        data.append("nombre", formData.value.firtsname);
        data.append("apellidos", formData.value.lastname);
        data.append("email", formData.value.email);
        data.append("phone", formData.value.phone);
        data.append("owners", formData.value.owners);
        data.append("diagnostico", formData.value.diagnostic_file);
        data.append("mileage", mileage);

        data.append("marca[name]", new_data["brand"]["name"]);
        data.append("marca[id]", (new_data["brand"]["id"]["id"] != undefined)? new_data["brand"]["id"]["id"]: new_data["brand"]["id"]); 

        data.append("modelo[name]", new_data["model"]["name"]);
        data.append("modelo[id]", (new_data["model"]["id"]["id"] != undefined)? new_data["model"]["id"]["id"]: new_data["model"]["id"]); 
        
        data.append("year[name]", new_data["year"]["name"]);
        data.append("year[id]", (new_data["year"]["id"]["id"] != undefined)? new_data["year"]["id"]["id"]: new_data["year"]["id"]); 

        data.append("version[name]", new_data["version"]["name"]);
        data.append("version[id]", (new_data["version"]["id"]["id"] != undefined)? new_data["version"]["id"]["id"]: new_data["version"]["id"]); 
        
        // data.append("modelo[id]", new_data.model.id); 
        // data.append("modelo[name]", new_data.model.name);
        // data.append("year[id]", new_data.year.id); 
        // data.append("year[name]", new_data.year.name);
        // data.append("version[id]", new_data.version.id); 
        // data.append("version[name]", new_data.version.name);

        // data.append("brand_id", formData.value.brand.id); 
        // data.append("brand_name", formData.value.brand.name); 

        // console.log(formData.value);
        // console.log(formData.value.brand.id);

        ApiService.post("api/leads/avaluo", data).then((result) => {
          if(result.data.success){
            loading.value = false;
            emit("updateList");

            currentStepIndex.value = 2;
            previousStep();

            currentStepIndex.value = 1;
            previousStep();

            formData.value = {
              type_appraisal: "Compra directa",
              firtsname: "",
              lastname: "",
              email: "",
              phone: "",  
              brand: { id: "", name: "Selecciona una marca" },
              model: { id: "", name: "Selecciona un modelo" },
              version: { id: "", name: "Selecciona una versión" },
              year: { id: "", name: "Selecciona una año" },
              owners: 1,
              mileage: "",
              diagnostic_file: { name:"", file:{} },
            };

            resetForm({
              values: {
                ...formData.value,
              },
            });
              
            setTouched({});

            currentStepIndex.value = 0;
            previousStep();
            
            Swal.fire({
              html:'<p class="fw-bold text-white">Avalúo creado</p> <p>Se ha agendado de manera correcta el avalúo</p>',
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Cerrar",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {
              closeModal();
              // router.go(0);
            });
          }
        }).catch((error: any) => {
          loading.value = false;
          console.log("data", data);
          currentStepIndex.value = 2;

          if(typeof error.success !== "undefined" && error.success == false){
            Swal.fire({
              text: error.message,
              icon: "error",
            }).then(() => {
              console.log("error");
            });
          } else {
            Swal.fire({
              icon: "error",
              text:
                typeof error.response.data.errors === "object"
                  ? Object.values(error.response.data.errors)
                      .toString()
                      .replace(",", "</br>")
                  : error.response.data.message,
            }).then(() => {
              console.log("error");
            });
          }
        });
      };

      const openModal = () => {
        showModal(refModalCreateAppraisal.value);
      };

      const closeModal = () => { 
        resetForm();

        formData.value = {
          type_appraisal: "Compra directa",
          firtsname: "",
          lastname: "",
          email: "",
          phone: "",  
          brand: { id: "", name: "Selecciona una marca" },
          model: { id: "", name: "Selecciona un modelo" },
          version: { id: "", name: "Selecciona una versión" },
          year: { id: "", name: "Selecciona una año" },
          owners: 1,
          mileage: "",
          diagnostic_file: { name:"", file:{} },
        };

        resetForm({
            values: {
              ...formData.value,
            },
        });
        
        if(currentStepIndex.value == 3){
          previousStep();
        }

        if(currentStepIndex.value == 2){
          previousStep();
        }

        if(currentStepIndex.value == 1){
          previousStep();
        }

        currentStepIndex.value = 0;
        previousStep();
        
        hideModal(refModalCreateAppraisal.value);
      };

      async function onChangeTypeAppraisal(type_appraisal){
        formData.value.type_appraisal = type_appraisal;
        // resetForm();
      }

      const getBrands = async () => {
        loading.value = true;
        let url = "/api/maxi/brands/v_1";

        try {
          const { data } = await ApiService.get(url);
          listBrands.value = data.data;
          
        } catch (error){
          console.log(error);
        }
        
        loading.value = false;
      };

      const getModels = async () => {
        loading.value = true;
        listModels.value = [];
        listYears.value = [];
        listVersions.value = [];

        formData.value.model = { id: "", name: "" };
        formData.value.year = { id: "", name: "" };
        formData.value.version = { id: "", name: "" };
        
        try {
          const { data } = await ApiService.get(`/api/maxi/models/${formData.value.brand.id}`);
          listModels.value = data.data;
          
        } catch (error){
          console.log(error);
          listModels.value = [];
        }

        loading.value = false;
      };

      const getYears = async () => {
        loading.value = true;
        listYears.value = [];
        listVersions.value = [];
        formData.value.year = { id: "", name: "" };
        formData.value.version = { id: "", name: "" };
        const model: any = listModels.value.find((m) => m.id == formData.value.model.id);
        formData.value.model = model;

        try {
          const { data } = await ApiService.get(`/api/maxi/years/${formData.value.model.id}`);
          listYears.value = data.data;
        } catch (error){
          console.log(error);
        }

        loading.value = false;
      };

      const getVersions = async () => {
        loading.value = true;
        listVersions.value = [];
        formData.value.version = { id: "", name: "" };
        
        try {
          const { data } = await ApiService.get(`/api/maxi/versions/${formData.value.year.id}`);
          listVersions.value = data.data;
        } catch (error){
          console.log(error);
        }
        
        loading.value = false;
      };

      const setBrand = async (goAndCome = false) => {
        
        await getBrands();
        let timeout = false
        if (currentStepIndex.value == 1  && goAndCome){
            const brand: any = listBrands.value.find(
            (v) => {
              timeout = true
              return v.id == brandTemp.value
            }
          )
            if (timeout) formData.value.brand = brand;
           
        } else if (!goAndCome){
          const brand: any = listBrands.value.find(
            (v) => {
              timeout = true
              return v.id == formData.value.brand.id;
            })
          brandTemp.value = formData.value.brand.id;
          if (timeout) formData.value.brand = brand;
        }
      };

      const setModel = async (goAndCome = false) => {
        // let idObject = ""
        // typeof formData.value.brand.id["id"] === "object" 
        // ? idObject = formData.value.brand.id["id"]
        // : idObject = formData.value.brand.id;
        try {
          // console.log("objet", idObject["id"])
          const { data } = await ApiService.get(`/api/maxi/models/${ brandTemp.value != "" ? brandTemp.value : formData.value.brand.id}`);
          listModels.value = data.data;
          
        } catch (error){
          console.log(error);
          listModels.value = [];
        }
        let timeout = false
        if (currentStepIndex.value == 1  && goAndCome){
          const model: any = listModels.value.find(
            (v) => {
              timeout = true
              return v.id == modelTemp.value
            }
          )
          if (timeout) formData.value.model = model;
        }else if (!goAndCome){
          const model: any = listModels.value.find(
            (v) => {
              timeout = true
              return v.id == formData.value.model.id;
            }
          )
          if (timeout) formData.value.model = model;
          modelTemp.value = formData.value.model.id;
        }

      };

      const setYear = async (goAndCome = false) => {
        // await getYears();
        
        try {
          const { data } = await ApiService.get(`/api/maxi/years/${ modelTemp.value != "" ? modelTemp.value : formData.value.model.id}`);
          listYears.value = data.data;
        } catch (error){
          console.log(error);
        }
      let timeout = false
        if (currentStepIndex.value == 1 && goAndCome){
          
          const year: any = listYears.value.find(
            (v) => {
              timeout = true
              return v.id == yearTemp.value
            }
          )
            if (timeout) formData.value.year = year;
        }else if (!goAndCome){
          const year: any = listYears.value.find(
            (v) => {
              timeout = true
              return v.id == formData.value.year.id
            }
          )
          if (timeout) formData.value.year = year;
          yearTemp.value = formData.value.year.id;
        }
      
      };

      const setVersion = async (goAndCome = false) => {
        // await getVersions();
        try {
          const { data } = await ApiService.get(`/api/maxi/versions/${yearTemp.value != "" ? yearTemp.value : formData.value.year.id}`);
          listVersions.value = data.data;
        } catch (error){
          console.log(error);
        }
        let timeout = false
        if (currentStepIndex.value == 1  && goAndCome){
          
          const version: any = listVersions.value.find(
            (v) => {
              timeout = true
              return v.id == versionTemp.value; 
            }
          )
            if (timeout) formData.value.version = version;
        }else if (!goAndCome){
          const version: any = listVersions.value.find(
            (v) => {
              timeout = true
              return v.id == formData.value.version.id; 
            }
          )
          if (timeout) formData.value.version = version;
          versionTemp.value = formData.value.version.id;
        }

      };

      onMounted(async () => {
        _stepperObj.value = StepperComponent.createInsance( createAppRef.value as HTMLElement );
        await getBrands();
      });

      return {
        types_appraisal,
        formData,
        totalSteps,
        currentStepIndex,
        
        loading,
        createAppRef,
        refModalCreateAppraisal,
        
        handleStep,
        openModal,
        closeModal,

        getBrands,
        getModels,
        getYears,
        getVersions,
        
        setBrand,
        setModel,
        setYear,
        setVersion,

        addKm,
        formSubmit,
        previousStep,
        
        listBrands,
        listModels,
        listYears,
        listVersions,
        
        files,
        filediagnostic,
        inputFile,
        fileload,
        isEdit,

        dropEvent,
        checkData,
        getFilesInput,
        addImage,
        deleteImage,

        testNumber,
        error_file,
        getIllustrationsPath,
        onChangeTypeAppraisal,
        confirmTosave,

        brandTemp,
        modelTemp,
        yearTemp,
        versionTemp

      };
    },

    methods: {
      formatPrice(value){
        let val = (value / 1).toFixed(2);
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },

      formatKm(value){
        let val = (value / 1).toFixed(2);
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " KM";
      },
    },
  });
