
/* eslint-disable no-undef */
import { onMounted, ref, onBeforeMount, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Field, ErrorMessage } from "vee-validate";

import ApiService from "@/core/services/ApiService";
import SolanaServices from "@/core/services/SolanaServices";
import store from "@/store";
import _ from "lodash";
import DataTable from "@/components/common/TableData.vue";
import ModalCreateAppraisal from "@/components/appraisals/ModalCreateAppraisal.vue";

export default {
  name: "AppraisalsList",
  components: {
    Field,
    DataTable, 
    ModalCreateAppraisal, 
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const userRole = ref<any>();
    const tipoRol = ref<string>("");
    
    const listBrands = ref<any>([]);
    const listModels = ref<any>([]);
    const listStatus = ref<any>([]);
    const listYears = ref<any>([]);
    
    const refModalCreateAppraisal = ref<any>(null);
    const params = ref<any>();
    const idAgency = ref<any>("");
      
    let wordToSearch = ref<string>("");
    let selectedBrand = ref<string>("Marca");
    let selectedModel = ref<string>("Modelo");
    let selectedStatus = ref<string>("Estatus");
    let selectedYear = ref<string>("Año");

    const showing = ref(10);
    const loading = ref<boolean>(false);
    const noResults = ref<boolean>(true);
    const tableRows = ref<any>([]);
    const links = ref<any>([]);
    const total = ref<number>(0);
    const from = ref<number>(0);
    const to = ref<number>(0);
    
    const tableHeader = ref<any>([
      // {
      //   name: "",
      //   key: "valuation_id",
      //   sortable: true,
      // },
      {
        name: "Cliente",
        key: "customer_name",
        sortable: true,
      },
      {
        name: "Vehiculo",
        key: "vehicle",
        sortable: true,
      },
      // {
      //   name: "Año",
      //   key: "year",
      //   sortable: true,
      // },
      // {
      //   name: "Marca",
      //   key: "brand",
      //   sortable: true,
      // },
      // {
      //   name: "Modelo",
      //   key: "model",
      //   sortable: true,
      // },
      {
        name: "Estatus",
        key: "status",
        sortable: true,
      },
    ]);

    const getStatus = async () => {
      try {
        const { data } = await ApiService.get("/api/leads/avaluos-status");
        listStatus.value = data.data;

        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    const getBrands = async () => {
      try {
        const { data } = await ApiService.get("/api/vehicle-client-brands");
        listBrands.value = data.data;

        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    const getModels = async () => {
      try {
        const { data } = await ApiService.get("/api/models");
        listModels.value = data.data;

        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    const getYears = async () => {
      try {
        const { data } = await ApiService.get("/api/vehicle-client-years");
        listYears.value = data.data;

        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    const getTipoRol = async () => {
        const {data} = await SolanaServices.getTipoRol();
        tipoRol.value = data.data[0].type;
    }

    const showingSet = async () => {
      tableRows.value.splice(0);
      links.value.splice(0);
      await getData(params.value);
    };

    const getPagination = async (link) => {
      tableRows.value.splice(0);
      let urlProd = link.url;
      if(urlProd.search('seminuevossolana') > 0){
        urlProd = urlProd.replace('http', 'https');
      }
      await getData(urlProd);
    };

    const search = async () => {
      let filters = "";

      (wordToSearch.value != "")? filters += "&search=" + wordToSearch.value : false ;
      (selectedBrand.value != "" && selectedBrand.value != "Marca")? filters += "&brand=" + selectedBrand.value : false;
      (selectedYear.value != "" && selectedYear.value != "Año")? filters += "&year=" + selectedYear.value : false;
      
      if( selectedStatus.value != "" && selectedStatus.value != "Estatus" ){
        for( let key in listStatus.value ){
          if( listStatus.value[key].id == selectedStatus.value || listStatus.value[key].status == selectedStatus.value ){
            filters += "&status=" + listStatus.value[key].id;
            selectedStatus.value = listStatus.value[key].status;
            break;
          }
        }
      }

      await getData(filters);
    };

    const getData = async (filters = "") => {
      loading.value = true;
      
      if( !filters.includes("type") ){
        tipoRol.value != undefined && tipoRol.value == "Corporativo" ? (filters += "&type=3") : false ;
        tipoRol.value != undefined && tipoRol.value == "Agencia" ? (filters += "&type=1") : false;
        tipoRol.value != undefined && tipoRol.value == "Múltiples agencias" ? (filters += "&type=1") : false;
        tipoRol.value != undefined && tipoRol.value == "Ciudad" ? (filters += "&type=3") : false;
      }
      
      try {
        let url = "/api/leads/avaluos?showing=" + showing.value;
        
        if( filters.includes("https")) {
          url = filters;
        } else {
          url = "/api/leads/avaluos?showing=" + showing.value + filters;
        }
        
        // console.log("Filters " + filters);
        // console.log("URL", url);
        const { data } = await ApiService.get(url);
        // console.log(data);
        
        if( data.success) {
          tableRows.value.splice(0);
          
          total.value = data.data.total;
          links.value = data.data.links;
          from.value = data.data.from;
          to.value = data.data.to;

          if( data.data.data != undefined && data.data.data.length > 0) {
            noResults.value = false;
            
            data.data.data.forEach((value, index) => {
              tableRows.value.push({
                status: value.Estatus,
                vehicle: `${value.Año} ${value.Marca} ${value.Modelo}`,
                date_id: value.id_cita,
                date_type_id: value.tipo_cita,
                customer_id: value.user,
                customer_name: value.Cliente,
                vehicle_id: value.vehicle,
                valuation_id: value.id_valuation,
                appraiser: value.valuador,
              });
            });
          } else {
            total.value = 0;
            links.value = data.data.links;
            to.value = data.data.to;
            noResults.value = true;
          }
        }
      } catch (error) {
        console.log(error);
      }

      loading.value = false;
    };

    const openModalCreateAppraisal = () => {
      refModalCreateAppraisal.value!.openModal();
    };

    const can = (permissions) => {
      // return true;
      const Permissions = store.getters.getPermissions;

      if( _.size(Permissions)) {
        if( Array.isArray(permissions)) {
          let flag = false;

          _.map(permissions, (permission) => {
            if( Permissions.indexOf(permission) !== -1) {
              flag = true;
            }
          });

          return flag;
        } else {
          return Permissions.indexOf(permissions) !== -1;
        }
      }

      return false;
    };

    const download = async () => {
      try {
        let filters = "";

        userRole.value.name != undefined && userRole.value.name == "Administrador"? (filters += "&type=3") : false;
        userRole.value.name != undefined && userRole.value.name == "Valuador de agencia"? (filters += "&type=1") : false;
        userRole.value.name != undefined && userRole.value.name == "Coordinar de agencia"? (filters += "&type=1") : false;
        userRole.value.name != undefined && userRole.value.name == "Valuador a domicilio"? (filters += "&type=3"): false;

        (wordToSearch.value != "") ? (filters += "&search=" + wordToSearch.value) : false;
        (selectedBrand.value != "" && selectedBrand.value != "Marca")? filters += "&brand=" + selectedBrand.value : false;
        (selectedYear.value != "" && selectedYear.value != "Año")? filters += "&year=" + selectedYear.value : false;
        
        if( selectedStatus.value != ""  && selectedStatus.value != "Estatus" ){
          for( let key in listStatus.value ){
            if( listStatus.value[key].id == selectedStatus.value || listStatus.value[key].status == selectedStatus.value ){
              filters += "&status=" + listStatus.value[key].id;
              break;
            }
          }
        }
        const { data } = await ApiService.get("/api/leads/avaluos?report=1" + filters);

        let csvContent = "data:text/csv;charset=utf-8," + data;
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Listado_de_avalúos.csv");
        link.click();
        //window.open(encodedUri);
      } catch (error) {
        console.log(error);
      }
    };

    onBeforeMount(async () => {
      let userRolObj: any = localStorage.getItem("userRole");
      const Permissions = store.getters.getPermissions;
      userRole.value = JSON.parse(userRolObj);      
      let filter = "";

      // console.log(userRole.value);
      // console.log(Permissions);

      if( userRole.value.name != undefined && (userRole.value.name == "Administrador" || userRole.value.name == "Coordinador de agencia") ) {
        tableHeader.value.unshift({
          name: "Valuador",
          key: "appraiser",
          sortable: true,
        });
      } else if( userRole.value.name != undefined && userRole.value.name == "Valuador" && userRole.value.name == "Valuador a domicilio") {
        tableHeader.value = [
          {
            name: "Cliente",
            key: "customer_name",
            sortable: true,
          },
          {
            name: "Vehículo",
            key: "vehicle",
            sortable: true,
          },
          {
            name: "Estatus",
            key: "status",
            sortable: true,
          },
        ];
      } else if( userRole.value.name != undefined && userRole.value.name == "Coordinador de agencia" ) {
        tableHeader.value.push({
          name: "Acciones",
          key: "actions",
        });
      }
      
      await getTipoRol();
      await getStatus();
      await getBrands();
      await getYears();

      if( route.params.filter != undefined && route.params.filter != "" ){
        filter += "&status=" + route.params.filter ;

        for( let key in listStatus.value ){
          if( listStatus.value[key].id == route.params.filter.toString() ){
            selectedStatus.value = listStatus.value[key].status;
            break;
          }
        }
      }
      
      await getData(filter);
    });

    onMounted(async () => {
    
    });

    watch( () => route.params.idt,
        async newId => {
          idAgency.value = newId;
          
          getData("&agency=" + idAgency.value);
        }
    );

    return {
      showing,
      total,
      from,
      to,
      links,
      noResults,
      tableRows,
      tableHeader,
      userRole,

      listYears,
      listStatus,
      listBrands,
      listModels,

      getBrands,
      getModels,
      getStatus,
      getYears,
      getData,

      download,
      search,
      wordToSearch,

      showingSet,
      params,
      getPagination,

      refModalCreateAppraisal,
      openModalCreateAppraisal,
      
      selectedYear,
      selectedBrand,
      selectedModel,
      selectedStatus,

      getTipoRol,
      can,
      store,
      loading,
      idAgency
    };
  },
};
